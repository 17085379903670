<template>
  <v-container>
    <v-layout flex align-center justify-center style="margin-top: 20vh">
      <v-flex xs12 md6 sm6 xl4>
        <v-card >
          <v-card-title  class="justify-center display-1 mb-2">Administrativo</v-card-title>
          <v-card-subtitle class="text-center">Faça login para continuar</v-card-subtitle>
          <v-card-text class="pt-4">
            <div>
              <v-form v-model="valid" ref="form">
                <v-text-field
                    label="Login"
                    v-model="login"
                    outlined
                    :rules="loginRules"
                    required
                ></v-text-field>
                <v-text-field
                    label="Senha"
                    v-model="password"
                    outlined
                    type="password"
                    :rules="passwordRules"
                    required
                ></v-text-field>
<!--                <v-text-field-->
<!--                    outlined-->
<!--                    label="ID do Servidor"-->
<!--                    v-model="server"-->
<!--                    type="number"-->
<!--                    :rules="serverRules"-->
<!--                    required-->
<!--                ></v-text-field>-->
                <v-layout justify-space-between>
                  <v-btn color="primary" depressed x-large block :loading="loading" @click="submit" >Login</v-btn>
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import UserService from "@/services/UserService";

export default {
  name: 'Index',

  data() {
    return {
      valid: false,
      e1: false,
      password: '',
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
      loginRules: [
        (v) => !!v || 'Login is required',
      ],
      serverRules: [
        (v) => !!v || 'Server is required',
      ],
      login: '',
      loading : false,
      server: ""
    }
  },
  methods: {
    async submit() {
        this.loading = true;
        try {
          // const server = (await new ServerService().getServer(this.server)).data
          // localStorage.setItem("url",server.url)
          await new UserService(false).login({
            login: this.login,
            password: this.password,
          });
          await this.$router.push({
            name: "index",
          });
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
    },
  },
}
</script>

<style>
#app {
  background-color: #F2F5F8;
  overflow:hidden;
}

.loginOverlay {
  background:rgba(33,150,243,0.3);
}

.photoCredit{
  position: absolute;
  bottom: 15px;
  right: 15px;
}
</style>